(function ($) {
  $(function () {
    let slider = $('.testimonials-slider');
    if (slider.length)
      slider.slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      });
  });
})(jQuery);
