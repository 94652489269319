jQuery(function ($) {
  if (!$('section.press_release_repeater').length) {
    return;
  }

  let $year_select = $('#year-select');

  function hideShow() {
    $('.btn-see-more-container').hide();
    $(
      `.btn-see-more-container[data-year="${$year_select.val().toString()}"]`
    ).show();
  }

  hideShow();

  $year_select.change(function (e) {
    setTimeout(function () {
      $('.articles article').each(function () {
        if (
          $(this).attr('data-year') !== $year_select.val() &&
          $year_select.val() !== '-1'
        )
          $(this).hide();
        else $(this).show();
      });
      hideShow();
    }, 1);
    if (
      $(`.articles article[data-year="${$year_select.val()}"]`).length < 1 &&
      $year_select.val() !== '-1'
    ) {
      $('.no-results-text').show();
      $('.btn-see-more-articles').hide();
    } else {
      $('.no-results-text').hide();
      $('.btn-see-more-articles').show();
    }
  });

  let $load_more = $(`button.btn-see-more-articles`); //[data-year="${$year_select.val().toString()}"]`);
  let $articles = $('.press-release-articles .row');

  $load_more.click(function (e) {
    e.preventDefault();
    $(`button.btn-see-more-articles[data-year="${$year_select.val()}"]`)
      .addClass('active')
      .attr('disabled', 'disabled');
    ThemeJS.Functions.send_ajax_request({
      data: {
        action: 'load_more_posts',
        query: $load_more.data(),
        year: $year_select.val(),
      },
      success: function (response) {
        console.log(response);
        response.articles.forEach(function (value, index) {
          let $article = $(value);
          $article.css('transition-delay', `${150 * index}ms`);
          $article.addClass('ajax-loaded');
          $articles.append($article);
        });
        requestAnimationFrame(function () {
          $articles.children().removeClass('ajax-loaded');
        });
        if (response.more) {
          $(
            `button.btn-see-more-articles[data-year="${$year_select.val()}"]`
          ).data('paged', parseInt($load_more.data('paged')) + 1);
          console.log($load_more.data());
          $(`button.btn-see-more-articles[data-year="${$year_select.val()}"]`)
            .removeClass('active')
            .removeAttr('disabled');
        } else {
          $(
            `button.btn-see-more-articles[data-year="${$year_select.val()}"]`
          ).remove();
        }
      },
    });
  });
});
