jQuery(function ($) {
  if (!$('section.faq_selector').length) {
    return;
  }

  let $category_select = $('#category-select');

  $category_select.change(function (e) {
    setTimeout(function () {
      $('.faq-term').each(function () {
        if (
          $(this).attr('data-term') !== $category_select.val() &&
          $category_select.val() !== '-1'
        )
          $(this).hide();
        else $(this).show();
      });
    }, 1);
    // if (
    //   $(`.faq-term[data-term="${$category_select.val()}"]`).length < 1 &&
    //   $category_select.val() !== '-1'
    // ) {
    //   // $('.no-results-text').show();
    //   // $('.btn-see-more-articles').hide();
    // } else {
    //   // $('.no-results-text').hide();
    //   // $('.btn-see-more-articles').show();
    // }
  });
});
