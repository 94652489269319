//Automatic responsive videos in content editor

ThemeJS.Responsive_Video = (function ($, root) {
  // Run code for each element
  $('.embed-responsive').each(function () {
    // Get ar
    var ar = $(this).attr('data-aspectratio');

    // Check for existance and set padding-bottom
    if (typeof ar !== typeof undefined && ar !== false) {
      $(this).css('padding-bottom', (1 / ar) * 100 + '%');
    }
  });
})(jQuery, this);
